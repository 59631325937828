.attribution {
  position: fixed;
  bottom: 0.5rem;
  left: 0.9rem;
  z-index: 1;
  color: grey;
  font-size: 0.6rem;
  margin: 0 0.2rem 0 0.2rem;
}
.attribution a {
  color: grey;
  opacity: 0.6;
  text-decoration: underline;
}

.clusterMarker {
  width: 30;
  height: 30;
  border-radius: '50%';
  background-color: '#51D5A0';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  color: 'white';
  border: '2px solid #56C498';
  cursor: 'pointer';
}

.user_dot {
  margin: 4.3rem 0 0 4.3rem;
  background-color: #2094fb;
  background-size: cover;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  border: 5px solid white;
  box-shadow: 3px 4px 10px #949494;
}

.user_outer {
  background-color: #2094fb26;
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
}

.user_control,
.user_control_enabled,
.user_control_enabled_active {
  z-index: 2 !important;
  position: fixed !important;
  color: rgba(0, 0, 0, 0.5);
  top: 2.7rem;
  text-align: center;
  font-size: 1.5rem;
  margin: auto;
  background-color: white;
  z-index: 1;
  position: relative;
  border: 0;
  border-radius: 0.3rem;
  right: 0.7rem;
  width: 2rem;
  padding: 1rem;
  margin: 0;
  border: 2px solid #c7c7c7;
}

.user_control_enabled,
.user_control_enabled_active {
  border: 2px solid #2094fb;
  box-shadow: 3px 4px 10px #949494;
}

.user_control_enabled_active {
  color: #2094fb;
}
