.contextMenu {
  z-index: 9000;
  position: absolute;
  background-color: #d1d1d1bf;
  padding: 0;
  width: 11rem;
  box-shadow: 3px 4px 10px #949494;
  border-radius: 0.3rem;
  overflow: hidden;
}

.contextMenu_item_disabled,
.contextMenu_item {
  padding: 0.2rem 1rem;
}

.contextMenu_item_disabled {
  opacity: 0.2;
}

.contextMenu_item:hover {
  background-color: #54818c88;
  color: white;
}
