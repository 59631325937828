.container {
  display: flex;
  height: 100%;
  max-height: 11rem;
  display: flex;
}

.image {
  width: 100%;
  object-fit: contain;
  background-color: black;
}

/* Spinner */
.spinner,
.missing {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.missing:before {
  content: 'X';
  font-size: 3rem;
  opacity: 0.2;
}

.doublebounce1,
.doublebounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.doublebounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
