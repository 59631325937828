.container {
  z-index: 2;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 0.8rem;
  background-color: #ffffffb5;
  padding: 0.5rem;
  margin: 0;
  height: calc(100vh - 6rem);
  width: 21rem;
  border: 1px solid #c2c2ca;
  border-radius: 0.2rem;
  position: absolute;
  overflow: hidden;
  top: 3rem;
  left: 1rem;
}

.offset {
  top: 7.5rem;
  left: 0.5rem;
  height: calc(100vh - 9rem);
}

.playbackStatus {
  margin: 1rem 0 0 0;
  color: #85c9de;
  font-weight: 900;
  font-size: 1.2rem;
}

.footerButtons {
  margin: 0.9rem 0 0 0;
}

.resultsContainer {
  overflow: scroll;
  display: flex;
  flex-grow: 1;
  width: 21rem;
  border: 1px solid #00000011;
  margin: 0.8rem 0 0 0;
}

.noneFound {
  margin: 2rem 0 0 3.5rem;
  color: #e4b7b7;
  font-weight: 900;
  font-size: 1.2rem;
}

.scopeIcon {
  margin: 0 0.3rem 0 0;
  color: #00000055;
}

.searchScope {
  align-items: center;
  display: flex;
  margin: 0.2rem;
  background-color: #e2ffe094;
  border: 1px dashed #d8d8d8;
  flex-grow: 1;
  font-family: sans-serif;
  font-size: 0.2rem;
  font-size: 0.8rem;
  font-weight: 900;
  height: 1.9rem;
  overflow: hidden;
  padding: 0.3rem;
  white-space: nowrap;
}

.search_input.navigationbar {
  position: fixed;
  top: 2rem;
  right: 0;
}

.search_input_results {
  margin: 0.5rem 0 0;
  padding: 0;
  border-radius: 0 0 0 0.3rem;
  z-index: 3;
  background-color: transparent;
}

.search_input_results input {
  border: 1px solid #00000022;
  font-size: 1.5rem;
  height: 1rem;
  outline: none;
  padding: 0.5rem 0.5rem 0.5rem 0.4rem;
  width: 12.7rem;
  z-index: 3;
  flex-grow: 1;
  margin: 0 0.2rem 0 0;
}

.searchInput {
}

.search_input.collapsed {
}

.search_input.collapsed input {
  display: none;
}

.search_input input:focus {
  outline: none;
}

.searchIcon {
  color: #00000070;
  position: fixed;
  top: 2.5rem;
  right: 0.7rem;
  font-size: 1rem;
}

.resultsDisplay {
  background-color: #ffffff00;
  width: 25rem;
}

.latchButton {
  width: 10rem;
  margin: 0;
  color: #929292;
}

.latchButton .optionIcon {
  padding: 0;
  margin: 0 0.2rem 0 0;
  width: 2rem;
  text-align: center;
  border: 1px solid #0000001f;
  border-radius: 0.3rem;
  background-color: #d0d0d0;
  font-family: sans-serif;
  font-weight: 900;
  font-size: 0.8rem;
  color: #00000080;
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
}

.latchButton .optionIcon.off {
  opacity: 0.5;
}

.navigationbar .mapIcon {
  position: fixed;
  top: 2.5rem;
  right: 0.6rem;
}

.highlight {
  border-radius: 0.3rem;
  background-color: rgba(253, 253, 193, 0.41);
}

.pin {
  background: lightGrey;
  border-radius: 0.3rem;
  position: absolute;
  margin: -1rem 0 0 0;
  right: 0;
  font-size: 0.7rem;
}
