.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border: 0px solid blue;
  align-items: center;
  height: 5rem;
  margin: 2.5rem 0 0 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.timeline {
  background-color: grey;

  height: 0.5rem;
  width: 100%;
  border-radius: 0.3rem;
}

.timelineInner {
  position: absolute;
  background-color: #cc0000;
  height: 0.4rem;
  z-index: 10;
  margin: 0 0 0 0;
}

.handleLeft,
.handleRight {
  position: absolute;
  background-color: #cc0000;
  border-radius: 0.3rem;
  height: 2rem;
  width: 7px;
  z-index: 10;
}

.label,
.handleLabel {
  text-align: center;
  margin: -1rem -1rem -1rem -1rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font-size: 0.1rem;
}

.handleLabel {
  margin: 2rem -1rem -1rem -1rem;
  font-weight: 900;
  font-size: 0.3rem;
}

.marker {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  position: fixed;
  background-color: grey;
  border: 0.3rem solid grey;
  border-radius: 1rem;
  width: 0.3rem;
  height: 0.3rem;
  margin: 0 0 0 0;
  font-size: 0.3rem;
}

.monthMarker {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  position: fixed;
  background-color: lightGrey;
  width: 2px;
  margin: 0 0 0 0;
  font-size: 0.1rem;
}

.pin {
  background: lightGrey;
  border-radius: 0.3rem;
  position: absolute;
  margin: -2rem 0 0 0;
  right: 0;
  font-size: 0.7rem;
}
