.backArrow {
  padding: 0.1rem 0 0 0.2rem;
  height: 3.1rem;
  display: flex;
  align-items: center;
  border-radius: 0.3rem 0 0 0.3rem;
}

.previewButton {
  text-align: right;
  margin: 1rem 0 0 0;
}

.inspector_container {
  z-index: 1;
  font-weight: 200;
  font-size: 0.8rem;
  background-color: #ffffffb5;
  padding: 0.5rem;
  margin: 0;
  left: 1rem;
  height: calc(100vh - 6rem);
  width: 20.2rem;
  border: 1px solid #c2c2ca;
  border-radius: 0.2rem;
  position: fixed;
  top: 3rem;
  display: flex;
  flex-direction: column;
}

.offset {
  top: 7.5rem;
  left: 0.5rem;
  height: calc(100vh - 9rem);
}

.searchnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  background-color: #dbe4e6;
  height: 3rem;
  top: -0.5rem;
  left: -0.5rem;
  width: 21.2rem;
  margin: 0.5rem 0 0 0.5rem;
  z-index: 10;
}

.searchPagination {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  height: 3rem;
  margin: 1rem 0.2rem 1rem 0.2rem;
  padding: 0 0 0 0.3rem;
  border-left: 1px solid #c2c2ca;
  position: relative;
  top: 0.02rem;
}

.inspector_container input[type='checkbox'] {
  float: right;
  position: relative;
  top: 0.06rem;
  right: 0.1rem;
}

.inspectorBox {
  margin: 0 0 0 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20.7rem;
  height: calc(100vh - 6rem);
  position: relative;
}

.hasSearchReturn {
  margin: 2.5rem 0 0 0.2rem;
}

/*#e4e4e4;*/

input:disabled {
  border: 0 !important;
}

.tag {
  line-height: 1.5rem;
  position: relative;
  top: 0.4rem;
  margin: 0.2rem 0.2rem 0 0;
  background-color: #54818c55;
  color: #7b7070;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  font-weight: 900;
  padding: 0.2rem;
}

.inspector_container .returnIcon {
  position: fixed;
  top: 3.1rem;
  left: 20.4rem;
  font-size: 0.4rem;
}

.returnIcon .fas {
  color: #4041429e;
}

.private input,
.private input:disabled,
.private textarea {
  border: 0.1rem dashed #e4b7b7 !important;
}

.labelCheckbox_locked {
  position: absolute;
  right: 2rem;
  margin: 0 0 0 10;
}

.labelCheckbox_visible {
  position: absolute;
  right: 1rem;
  margin: 0 0 0 10;
}

.labelCheckbox_locked input,
.labelCheckbox_visible input {
  display: none;
}

.selection_dropdown {
  z-index: 9000;
  position: absolute;
  background-color: #ffffff;
  padding: 0;
  margin: 0.03rem 0 0 0.1rem;
  width: 20rem;
  border: 0 1px 1px 1px solid #9e9e9e;
  box-shadow: 3px 4px 10px #949494;
  font-size: 0.8rem;
  font-weight: 400;
  overflow: hidden;
  display: block;
  font-family: sans-serif;
  line-height: 1rem;
}

.unlocked {
  color: grey;
  opacity: 0.75;
}

.locked {
  color: #e4b7b7;
  opacity: 1;
}

.selection_dropdown_item {
  padding: 0.2rem 0 0.2rem 0.4rem;
}

.selection_dropdown_item.disabled {
  opacity: 0.2;
}

.selection_dropdown_item:hover {
  background-color: #54818c88;
  color: white;
}

.hidden {
  display: none;
}

.poiForm {
  margin: 0 1rem 0 1rem;
}

.poiForm input {
  width: 17rem;
}

.tabContainer {
  font-size: 1rem;
  border-bottom: 1px solid #cccccc;
}

.tab,
.tab_selected {
  display: inline-block;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-radius: 0.2rem 0.2rem 0 0;
  padding: 0.2rem 0.2rem 0 0.2rem;
  background: #cccccc;
  color: white;
  margin: 0.2rem 0.2rem 0 0;
}

.tab_selected {
  color: black;
  background: white;
  border-bottom: 1px solid white;
  top: 1rem;
}
