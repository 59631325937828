.wrapper {
  z-index: 3;
  position: fixed;
  width: 100vw;
  top: 2rem;
  left: 0;
}

.container {
  position: relative;
  margin: 8px auto 0 auto;
  width: 21rem;
  height: 10rem;
  padding: 1rem;
  border-radius: 0 0 0.3rem 0.3rem;
  background-color: rgba(0, 0, 0, 0.65);
}
