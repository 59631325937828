/* http://tobiasahlin.com/spinkit/ */
.errorMessage {
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  font-weight: 900;
  color: black;
  max-width: 15rem;
  margin: 1rem auto 0 calc(50% - 7.5rem);
  word-wrap: break-word;
}

.spinner_container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 10;
  font-weight: 900;
  text-align: center;
  color: #00000073;
  font-size: 2rem;
  background-color: #e2e2e2;
  opacity: 1;
}

.spinnerInternal {
  margin: calc(50vh - 2rem) 0 0 0;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.spinner {
  margin: 0 auto 0 auto;
  width: 70px;
  text-align: center;
}

.spinner_logo {
  width: 10rem;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
